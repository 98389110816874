import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { SectionWrapper } from '../../components/section-wrapper';
import { BannerMonthlyPromo } from '../offer/banner-monthly-promo';

import { AestheticMedicine } from './components/aesthetic-medicine-section';
import { MainSection } from './components/main-section';
import { TreatmentSection } from './components/treatment-section';
import { faqContent, treatmentsSections } from './constants';
import { FaqSection } from '../home/faq-section';
import { NewsletterSection } from '../home/newsletter';

export const TreatmentsView = () => {
  const data = useStaticQuery(graphql`
    query {
      botox: file(relativePath: { eq: "treatments/botox.png" }) {
        childImageSharp {
          gatsbyImageData(width: 664, quality: 100, formats: [WEBP])
        }
      }
      acid: file(relativePath: { eq: "treatments/kwas.png" }) {
        childImageSharp {
          gatsbyImageData(width: 664, quality: 100, formats: [WEBP])
        }
      }
      mesotherapy: file(relativePath: { eq: "treatments/mezoterapia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 664, quality: 100, formats: [WEBP])
        }
      }
      stimmulants: file(relativePath: { eq: "treatments/stymulator.png" }) {
        childImageSharp {
          gatsbyImageData(width: 664, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <>
      <MainSection />
      <TreatmentSection
        {...treatmentsSections.botox}
        image={data.botox.childImageSharp}
      />
      <TreatmentSection
        {...treatmentsSections.acid}
        image={data.acid.childImageSharp}
      />
      <SectionWrapper>
        <BannerMonthlyPromo />
      </SectionWrapper>
      <TreatmentSection
        {...treatmentsSections.stimmulants}
        image={data.stimmulants.childImageSharp}
      />
      <TreatmentSection
        {...treatmentsSections.mesotherapy}
        image={data.mesotherapy.childImageSharp}
      />
      <AestheticMedicine />
      <NewsletterSection />
      <FaqSection faqData={faqContent.faqData} />
    </>
  );
};
